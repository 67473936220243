<template>
  <div>
    <v-container class="text_google">
      <base-material-card
        icon="mdi-clipboard-text"
        title="วุฒิการศึกษา / ที่ตรงกับวิชาเอก (ยกเว้นวุฒิบริหารการศึกษา)"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn             
                large
                right
                depressed
                color="primary"
                @click.native="personnel_educationAdd()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>เพิ่มรายการ
              </v-btn>             
            </v-col>
          </v-row>
        </v-card>

        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="personnel_educations"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ item.id_branch }}</td>
              <td>{{ item.name_branch }}</td>
              <td>{{ item.name_sub_branch }}</td>              
              <td>{{ item.education_level }}</td>
              <td>{{ item.faculty_name }}</td>
              <td>{{ item.branch_name }}</td>         
              <td>{{ item.academy_name }}</td>
              <td>{{ item.year_finish }}</td>
              <td>{{ item.academic_results }}</td>
              <td>
                <v-icon
                  color="yellow"
                  @click.stop="personnel_educationEdit(item.id_red)"
                 
                >
                  mdi-pencil
                </v-icon>
              </td>
              <td>
                <v-icon
                  color="red"
                  @click.stop="personnel_educationDelete(item.id_red)"
                
                >
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <base-material-card color="warning" class="px-5 py-3">
        <template v-slot:heading>
          <div class="font-weight-light">ตรวจสอบสาขาวิชาเอก</div>
          <div class="font-weight-light">ข้อมูลสาขาวิชาในระบบ</div>
        </template>
        <v-card-text>
          <v-data-table
            :headers="header_branchs"
            :items="showbranchdata"
            :search="search_branch"
          >
            <template v-slot:top>
              <v-text-field
                v-model="search_branch"
                label="ค้นหา :"
                class="mx-4"
              ></v-text-field>
            </template>
             <template v-slot:item.actions="{ item }">
            <v-icon
              color="yellow"
              
              @click.stop="personnel_educationEdit(item.id_red)"
            >
              mdi-pencil
            </v-icon>          
          </template>
            <template v-slot:item.action_s="{ item }">            
            <v-icon
              color="red"
              
              @click.stop="personnel_educationDelete(item.id_red)"
            >
              mdi-delete
            </v-icon>
          </template>      
          </v-data-table>
        </v-card-text>
      </base-material-card>
      <!--addpersonnel_educationdialog  -->
      <v-layout row justify-center>
        <v-dialog
          v-model="addpersonnel_educationdialog"
          persistent
          max-width="80%"
          overlay-opacity="0.6"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              icon="mdi-clipboard-text"
              title="เพิ่มวุฒิการศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text class="text_google">
              <v-form ref="addpersonnel_educationform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-combobox
                          v-model="addpersonnel_education.education_level"
                          :items="education_level"
                          item-value="education_level"
                          label="ระดับการศึกษา"
                          dense
                          :rules="[v => !!v || '']"
                          filled
                          prepend-icon="mdi-school"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          label="ประเภทสาขาวิชา ที่สอดคล้องกับ สอศ:"
                          prepend-icon="mdi-school"
                          request
                          v-model="addpersonnel_education.id_branch"
                          :rules="[v => !!v || '']"
                          filled
                          @change="branch_sub_dQueryAll()"
                          @select="branch_sub_dQueryAll()"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="5">
                        <v-autocomplete
                          :items="branch_sub_ds"
                          item-text="name_sub_branch"
                          item-value="id_branch_sub"
                          label="กลุ่ม วุฒิการศึกษา ที่สอดคล้องกับระบบ :"
                          prepend-icon="mdi-school"
                          request
                          v-model="addpersonnel_education.ed_id_branch_sub"
                          :rules="[v => !!v || '']"
                          filled
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="addpersonnel_education.faculty_name"
                          dense
                          label="วุฒิการศึกษา ตัวอย่าง บธ.บ. : "
                          item-value="cat_name"
                          prepend-icon="mdi-school"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="addpersonnel_education.branch_name"
                          dense
                          label="สาขาวิชา : ระบบสารสนเทศทางคอมพิวเตอร์"
                          item-value="cat_name"
                          prepend-icon="mdi-school"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="addpersonnel_education.academy_name"
                          dense
                          label="สถานศึกษาที่จบ : มหาวิทยาลัย"
                          item-value="cat_name"
                          prepend-icon="mdi-city"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="addpersonnel_education.year_finish"
                          label="สำเร็จปีการศึกษา : 2553"
                          prepend-icon="mdi-calendar"
                          filled
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="addpersonnel_education.academic_results"
                          dense
                          label="ผลการเรียนเรียน : 4.00"
                          item-value="cat_name"
                          prepend-icon="mdi-barcode"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-layout>
                  <v-row>
                    <v-col cols="12" lg="12" class="text-right">
                      <v-btn
                        large
                        color="success"
                        @click.stop="addpersonnel_educationSubmit()"
                        rounded
                      >
                        <v-icon dark>mdi-content-save</v-icon
                        >&nbsp;&nbsp;บันทึก</v-btn
                      >
                      <v-btn
                        large
                        color="warning"
                        @click.stop="addpersonnel_educationdialog = false"
                        rounded
                      >
                        <v-icon dark>mdi-close</v-icon>ยกเลิก
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletepersonnel_educationdialog -->
      <v-layout>
        <v-dialog
          v-model="deletepersonnel_educationdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูล"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text class="text_google">
              <v-form ref="deletepersonnel_educationform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12>
                     
                      <h3> ยืนยันการลบข้อมูล : {{ editpersonnel_education.education_level }}</h3>
                    </v-flex>
                    <v-flex xs12 md6></v-flex>
                    <v-flex xs12 md6></v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="deletepersonnel_educationdialog = false"
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletepersonnel_educationubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model editpersonnel_educationdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="editpersonnel_educationdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลการศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="editpersonnel_educationform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-row>
                      <v-col cols="12" md="3">
                        <v-combobox
                          v-model="editpersonnel_education.education_level"
                          :items="education_level"
                          item-value="education_level"
                          label="ระดับการศึกษา"
                          dense
                          :rules="[v => !!v || '']"
                          filled
                          prepend-icon="mdi-school"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="12" md="4">
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          label="ประเภทสาขาวิชา ที่สอดคล้องกับ สอศ:"
                          prepend-icon="mdi-school"
                          request
                          v-model="editpersonnel_education.id_branch"
                          :rules="[v => !!v || '']"
                          filled
                          @change="branch_sub_d_editQueryAll()"
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="5">
                        <v-autocomplete
                          :items="branch_sub_ds"
                          item-text="name_sub_branch"
                          item-value="id_branch_sub"
                          label="กลุ่ม วุฒิการศึกษา ที่สอดคล้องกับระบบ :"
                          prepend-icon="mdi-school"
                          request
                          v-model="editpersonnel_education.ed_id_branch_sub"
                          :rules="[v => !!v || '']"
                          filled
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editpersonnel_education.faculty_name"
                          dense
                          label="วุฒิการศึกษา ตัวอย่าง บธ.บ. : "
                          item-value="cat_name"
                          prepend-icon="mdi-school"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-text-field
                          v-model="editpersonnel_education.branch_name"
                          dense
                          label="สาขาวิชา : ระบบสารสนเทศทางคอมพิวเตอร์"
                          item-value="cat_name"
                          prepend-icon="mdi-school"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          v-model="editpersonnel_education.academy_name"
                          dense
                          label="สถานศึกษาที่จบ : มหาวิทยาลัย"
                          item-value="cat_name"
                          prepend-icon="mdi-city"
                          request
                          :rules="[v => !!v || '']"
                          filled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editpersonnel_education.year_finish"
                          label="สำเร็จปีการศึกษา : 2553"
                          prepend-icon="mdi-calendar"
                          filled
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-text-field
                          v-model="editpersonnel_education.academic_results"
                          dense
                          label="ผลการเรียนเรียน : 4.00"
                          item-value="cat_name"
                          prepend-icon="mdi-barcode"
                          filled
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="editpersonnel_educationdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="editpersonnel_educationSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;แก้ไขข้อมูล
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        multi-line
        vertical
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <br />
        {{ snackbar.text }}
        <v-btn dark @click="snackbar.show = false">Close</v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search_branch: "",
      ApiKey: "HRvec2021",
      loading: true,
      updateImageDialog: false,
      search: "",
      addpersonnel_educationdialog: false,
      editpersonnel_educationdialog: false,
      deletepersonnel_educationdialog: false,
      showimagedialog: false,
      periods: [],
      period_enable: "",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      currentPK: null,
      headers: [
        { text: "#", align: "center", value: "number_row" },
        { text: "รหัสประเภทสาขา", align: "center", value: "id_branch" },
        { text: "ประเภทสาขาวิชา สอศ", align: "left", value: "name_branch" },
        { text: "วุฒิการศึกษาตรงตามระบบ", align: "left", value: "name_sub_branch" },
        { text: "ระดับการศึกษา", align: "center", value: "education_level" },
        { text: "คณะวิชา", align: "center", value: "faculty_name" },
        { text: "สาขาวิชาที่จบการศึกษา", align: "center", value: "branch_name" },      
        { text: "จบจาก", align: "center", value: "academy_name" },
        { text: "ปีที่จบ", align: "center", value: "year_finish" },
        { text: "ผลการเรียน", align: "center", value: "academic_results" },
        {
          text: "แก้ไข",
          align: "center",
          value: "actions",
          icon: "mdi-file-document-edit"
        },
        {
          text: "ลบ",
          align: "center",
          value: "action_s",
          icon: "mdi-delete-forever"
        }
      ],
      header_branchs: [
        { text: "รหัสสาขา", align: "center", value: "id_branch" },
        { text: "สาขาวิชา", align: "left", value: "name_branch" },
        { text: "รายละเอียด", align: "left", value: "detail_branch" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      pagination: {},
      personnel_education: [],
      addpersonnel_education: {},
      editpersonnel_education: [],
      personnel_educations: [],
      personnel_education_sub: [],
      branch_s: [],
      showbranchdata: [],
      branch_sub_ds: [],
      education_level: ["ปริญญาตรี", "ปริญญาโท", "ปริญญาเอก"]
    };
  },

  async mounted() {
    let result_branch;
    result_branch = await this.$http.post("branch.php", {
      ApiKey: this.ApiKey
    });
    this.branch_s = result_branch.data;    
    this.personnel_educationsQueryAll();
    this.getAllbranchdata();
  },

  methods: {
    async branch_sub_dQueryAll() {
      let result = await this.$http.post("branch_sub_d.php", {
        ApiKey: this.ApiKey,
        id_main_branch: this.addpersonnel_education.id_branch
      });
      this.branch_sub_ds = result.data;
    },

      async branch_sub_d_editQueryAll() {
      let result = await this.$http.post("branch_sub_d.php", {
        ApiKey: this.ApiKey,
        id_main_branch: this.editpersonnel_education.id_branch
      });
      this.branch_sub_ds = result.data;
    },

    async personnel_educationsQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("personnel_education_etc.php", {
          ApiKey: this.ApiKey,
          id_card: userSession.personnel_etc_id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_educations = result.data;
 
    },

    //Add data
    async personnel_educationAdd() {
      this.addpersonnel_education = {};
      this.addpersonnel_educationdialog = true;
    },

    async addpersonnel_educationSubmit() {
      if (this.$refs.addpersonnel_educationform.validate()) {
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.addpersonnel_education.ApiKey = this.ApiKey;
        this.addpersonnel_education.id_card = userSession.personnel_etc_id_card;
        let result = await this.$http.post(
          "personnel_education.insert.php",
          this.addpersonnel_education
        );


      
        if (result.data.status == true) {
          this.personnel_education = result.data;
           Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.personnel_educationsQueryAll();
        } else {
          Swal.fire({
              icon: "warning",
              title: "ดำเนินการผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
        }
        this.addpersonnel_educationdialog = false;
      }
    },

    //Edit data
    async personnel_educationEdit(id_red) {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_red: id_red
      });
      this.editpersonnel_education = result.data;
      this.editpersonnel_educationdialog = true;
    },

    async editpersonnel_educationSubmit() {
      if (this.$refs.editpersonnel_educationform.validate()) {
        this.editpersonnel_education.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "personnel_education.update.php",
          this.editpersonnel_education
        );

        if (result.data.status == true) {
          this.personnel_education = result.data;
          Swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.personnel_educationsQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'แก้ไขข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.editpersonnel_educationdialog = false;
      }
    },

    //DELETE data
    async personnel_educationDelete(id_red) {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_red: id_red
      });
      this.editpersonnel_education = result.data;
      this.deletepersonnel_educationdialog = true;
    },

    async deletepersonnel_educationubmit() {
      if (this.$refs.deletepersonnel_educationform.validate())
        this.editpersonnel_education.ApiKey = this.ApiKey;
      {
        let result = await this.$http.post(
          "personnel_education.delete.php",
          this.editpersonnel_education
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: 'success',
            title: 'ลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.personnel_educationsQueryAll();
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ลบข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.deletepersonnel_educationdialog = false;
      }
    },
    async getAllbranchdata() {
      let result = await this.$http.post("branch.php", {
        ApiKey: this.ApiKey
      });
      this.showbranchdata = result.data;
    }
  },

  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
</script>
<style>
.v-data-table thead th {
  font-size: 16px !important;
}
</style>
